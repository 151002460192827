@keyframes hide {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes show {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

[hidden] {
	display: none !important;
}

body {
	background: rgb(0, 35, 63);
	margin: 0;
	font-family: -system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 17px;
	overflow-x: hidden;
}

div.pg {
	position: relative;
	width: 100%;
	min-height: 101vh;
	margin: 0 auto;
}

div.landPage {
	font-size: 1.45em;
	border: 2px solid #080;
	border-radius: 10%;
	padding: 1em 0.4em;
	margin:0.1em auto;
	background: #ED5;
}
.nav{
	cursor: pointer;
}
.hiding {
	animation: hide 0.4s ease-out;
	will-change: opacity;
}

.showing {
	animation: show 0.5s ease-out;
	will-change: opacity;
}

.ex {
	color: yellow;
	background-color: red;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1.5em;
	font-weight: bold;
	padding: 0px 8px;
	position: absolute;
	right: 5px;
	top: 5px;
	border: 2px solid yellow;
	border-radius: 50%;
	cursor: pointer;
	transition: background-color 0.1s cubic-bezier(0.215, 0.610, 0.355, 1);
	will-change: background-color;
}

.ex:hover {
	color: red;
	background: white;
	padding: 0 8px;
	border: 2px solid red;
}
.bold{
	font-weight: bold;
}
.ital{
	font-style: italic;
}

div.article {
	max-width: 1000px;
	margin: auto;
}

div.banner {
	left: 0;
	top: 0;
	background: #FF0;
	width: 96%;
	min-height: 90px;
	padding: 5px 2%;
}

div.menu {
	border-top: 4px solid #018bb8;
	border-bottom: 1px solid #FFF;
	font-size: 14px;
	background: #090;
	background: -moz-linear-gradient(top, #1e1e1e 0%, #040404 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1e1e1e), color-stop(100%, #040404));
	background: -webkit-linear-gradient(top, #1e1e1e 0%, #090 100%);
	background: linear-gradient(top, #1e1e1e 0%, #040404 100%);
	width: 100%;
	position: fixed;
	top:0;
	z-index: 1000;
}

a{
	text-decoration:none;
}
a:hover{
	text-decoration:underline;
}
div.siteName {
	width: 76%;
	max-width: 1800px;
	text-align: center;
	position: absolute;
	top: 20%;
	left: 12%;
}	
	div.siteName div{
		font-family: verdana;
		font-size: 0.8em;
		color: #FFF;
		height: 30px;
		width: 60%;
		margin-top: -10px;
		margin-right: auto;
		margin-bottom: 30px;
		margin-left: auto;
		font-size: 0.85rem;
	}

label{
	font-weight: bold;
	vertical-align: top;
}
div.title {
	text-align: center;
	font-size: 1.8em;
	font-weight: bold;
	font-family: Helvetica, verdana, arial, sans serif;
	margin-top: 0;
	margin-bottom: 1em;
}
div.title span {
	background: #080;
	color: #fff;
	padding: 0.3em 2em;
	border: 2px double #FB0;
	border-radius:0 0 50% 50%;
}

p.topic {
	font-size: 1.8rem;
}

div.pgContent {
	display: inline-block;
	width: 79%;

}

div.displayBox{
	position:absolute;
	top:20%;
	width:10%;
	font-size:80%;
	border:3px solid #FF0;
}

div.displayBox h3{
	text-align:center;
	margin-top:2px;
}

div.quote, p.quote {
	font-style: italic;
	margin: 0.5rem auto;
	width: 90%;
}
div.content {
	position: absolute;
	top: 50px;
	left: 7%;
	width: 80%;
	background: #FEFFFF;
	padding: 0 2em 2em 2em;
	border-radius: 0 0 20px 20px;
	border: 2px double #090;
	z-index: 10;
}

div.lftSide{
	display:inline-block;
	vertical-align: top;
	border-right: 1px solid #090;
	width: 100%;
	min-width: 160px;
}

div.rtSide{
	display:inline-block;
	vertical-align: top;
}

.art{
	color:#FFF;
	font-weight:450;
	cursor:pointer;
	margin:0.1em;
	display:block;
	background:#082;
	padding: 0.2em 0.5em;
	border-radius: 0 0.4em 0 0.4em;
}
.active, .art:hover {
	color:#FB0;
	background-color:#013;
}
.is-active{
	background-color: #013
}

p.bkRef{
	text-align:right;
	font-size: 0.8rem;
	font-style: italic;
	margin-top: 0;
}
div.article p:first-of-type {
	margin-top: 0;
}

.tooltip {
  position: relative;
  display: inline;
  border-bottom: 1px dotted black;
  color: #051;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  opacity: 0;
  transition: opacity 1s;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover{
  cursor:pointer;
  color: #610;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip .tooltiptext {
  bottom: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}
div.footer{
	width: 100%;
	position: absolute;
	bottom: 0.5rem;
	font-size: 0.8rem;
	color: white;
	text-align: center;
}

.grid {
	display: grid;
    grid-template-columns: 20% 1fr;
    grid-gap: 50px;
}

.fab {
	display: none;
	background-color: white;
	color: black;
	cursor: pointer;
	position: fixed;
	width: 50px;
	bottom: 0;
	right: 10px;
	z-index: 12;
}

.fab.active {
	display: block;
}

.fab svg {
	width: 100%;
	height: 100%;
}

.hamburger {
	padding: 15px 15px;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
}

.hamburger:hover {
	opacity: 0.7;
}

.hamburger.is-active:hover {
	opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
	background-color: #fff;
}

.hamburger-box {
	width: 30px;
	height: 18px;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 30px;
	height: 3px;
	background-color: #eeda10;
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
	content: "";
	display: block;
}

.hamburger-inner::before {
	top: -10px;
}

.hamburger-inner::after {
	bottom: -10px;
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
	transition-duration: 0.075s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
	transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
	transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
	transform: rotate(45deg);
	transition-delay: 0.12s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
	top: 0;
	opacity: 0;
	transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
	bottom: 0;
	transform: rotate(-90deg);
	transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media all and (max-width: 1000px) {
	div.pg {
	position: relative;
	width: 98%;
	min-height: 101vh;
	margin: 0 auto;
}
	div.siteName {
		top: 20%;
		left: 5%;
		font-size: 1rem;
		width: 88%;
		max-width: 900px;
	}
	div.content {
		top: 70px;
		left:0;
		width:95%;
		padding: 0 1em 1em 1em;
	}

	div.article {
		column-count: 1;
	}
	div.displayBox{
		transform:scaleX(0.75);
		transform:scaleY(0.75);
		border:3px solid #FFF;
		font-size:50%;
		top:30%;
	}
	div.title span{
		font-size: 1.1em;
		padding: 0.1em 0.5em;
		border: 2px double #FB0;
		border-radius:0 0 50% 50%;
	}
	div.menu {
		/*background: 0;*/
		width: fit-content;
		position: fixed;
		top:0;
		right: 0;
		z-index: 1000;
	}
	
	.grid {
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
		grid-gap: 10px;
	}
	
	.grid > :nth-child(1) {
		border-bottom: 1px solid #000;
	}
	
	div.lftSide {
		border-right: 0;
	}
	div.lftSide p {
		margin: 5px;
	}
}